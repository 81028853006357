<template>
  <div>
    <!-- 客户列表 -->
    <en-table-layout
      tips
      :tableData="tableData.data"
      @selection-change="handleSelectionChange"
      :loading="loading"
      ref="multipleTable"
      :rowKey="(row)=>{return row.ask_id}"
    >
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <div class="conditions">
            <div>状态选择：</div>
            <el-select
              class="ipt-default"
              style="width:150px"
              v-model="advancedForm.reply_status"
              clearable
            >
              <el-option value="" label="全部"></el-option>
              <el-option value="NO" label="未回复"></el-option>
              <el-option value="YES" label="已回复"></el-option>
            </el-select>
          </div>
          <div class="conditions">
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.keyword"
              placeholder="请输入客户昵称/客户手机号进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <el-button class="btn-default" @click="handleDeleteAll()">删除勾选</el-button>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55"
        ></el-table-column>
        <el-table-column prop="ask_id" label="编号" width="180" />
        <el-table-column label="微信昵称" width="180" >
          <template slot-scope="scope"><span v-html="scope.row.member_name"></span></template>
        </el-table-column>
        <el-table-column prop="mobile" label="客户手机号" width="180" />
        <el-table-column label="问题提交时间" width="180">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>

        <el-table-column prop="sex" label="状态" width="180">
          <template slot-scope="scope">{{ scope.row.reply_status==='YES'?'已回复':'未回复'}}</template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleScaleView(scope.row)">查看</el-button>
            <el-button size="mini" v-if="scope.row.reply_status === 'NO'" type="text" @click="handleScaleEdit(scope.row)">回复</el-button>
            <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--查看 dialog-->
    <el-dialog
      title="查看"
      :visible.sync="dialogMessageVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="MessageVisibleForm"
        :rules="MessageVisibleRules"
        ref="MessageVisibleForm"
        label-width="130px"
      >
        <el-form-item label="问题提交客户">
          <span>
            <span>{{MessageVisibleForm.member_name}}</span>
          </span>
        </el-form-item>
        <el-form-item label="问题提交时间">
          <span>
            <span>{{MessageVisibleForm.create_time | unixToDate('yyyy-MM-dd hh:mm:ss')}}</span>
          </span>
        </el-form-item>
        <el-form-item label="问题描述">
          <span>
            <span v-html="MessageVisibleForm.content"></span><br />
            <img v-if="MessageVisibleForm.ask_photo1" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo1"/>
            <img v-if="MessageVisibleForm.ask_photo2" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo2"/>
            <img v-if="MessageVisibleForm.ask_photo3" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo3"/>
          </span>
        </el-form-item>
        <el-form-item label="问题回复" v-if="MessageVisibleForm.reply_status === 'YES'">
          <span>
            <span v-html="MessageVisibleForm.reply"></span><br />
          </span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--回复 dialog-->
    <el-dialog
      title="回复"
      :visible.sync="dialogZhanneiMessageVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="ZhanneiMessageVisibleForm"
        :rules="ZhanneiMessageVisibleRules"
        ref="ZhanneiMessageVisibleForm"
        label-width="100px"
      >
        <el-form-item label="问题描述">
          <span>
            <span v-html="MessageVisibleForm.content"></span><br />
            <img v-if="MessageVisibleForm.ask_photo1" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo1"/>
            <img v-if="MessageVisibleForm.ask_photo2" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo2"/>
            <img v-if="MessageVisibleForm.ask_photo3" style="width:150px;height:auto;" :src="MessageVisibleForm.ask_photo3"/>
          </span>
        </el-form-item>
        <el-form-item label="回复内容" prop="reply">
          <el-input type="textarea" v-model="ZhanneiMessageVisibleForm.reply"></el-input>
        </el-form-item>
      </el-form>
      <!-- / -->
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogZhanneiMessageVisible = false">取 消</el-button>
        <el-button
          class="btn-default-dialog-yes"
          @click="submitMessageVisibleForm('ZhanneiMessageVisibleForm')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
export default {
  name: "feedback-list",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        keyword: "", // 根据关键字搜索
        times: "",
      },
      dialogMessageVisible: false,
      MessageVisibleForm: {
      },
      MessageVisibleRules: {
      },
      dialogZhanneiMessageVisible: false,
      ZhanneiMessageVisibleForm: {
        reply: "",
      },
      ZhanneiMessageVisibleRules: {
        reply: [
          { required: true, message: "请填写内容", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },

      ids_list: [], // 复选框数据
    };
  },

  mounted() {
    this.GET_MemberList();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_MemberList();
    next();
  },
  methods: {
    /** 当选择项发生变化 */
    handleSelectionChange(val) {
      this.ids_list = val.map((item) => item.ask_id);
    },
    handleDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes",
      })
        .then(() => {
          API_Member.deleteMemberAsk(row.ask_id)
            .then((res) => {
              this.$message.success("操作成功！");
              this.GET_MemberList();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handleDeleteAll() {
      if (this.ids_list.length === 0) {
        this.$message.error("请勾选要删除的条目！");
        return;
      }
      this.$confirm("确认要删除勾选的条目吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes",
      })
        .then(() => {
          API_Member.deleteMemberAskAll(this.ids_list.join(','))
            .then((res) => {
              this.$message.success("操作成功！");
              this.GET_MemberList();
              this.ids_list = []
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    /** 查看 */
    handleScaleView(row) {
      this.dialogMessageVisible = true;
      API_Member.getMemberAsksDetail(row.ask_id)
        .then((response) => {
          this.loading = false;
          response.member_name = unescape(response.member_name);
          this.MessageVisibleForm = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /** 查看 */
    handleScaleEdit(row) {
      this.dialogZhanneiMessageVisible = true;
      // this.ZhanneiMessageVisibleForm.reply = '';
      this.$nextTick(() => {
        this.$refs.ZhanneiMessageVisibleForm.resetFields();
      })
      API_Member.getMemberAsksDetail(row.ask_id)
        .then((response) => {
          this.loading = false;
          response.member_name = unescape(response.member_name);
          this.MessageVisibleForm = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_MemberList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_MemberList();
    },

    /** 搜索事件触发 */
    searchEvent() {
      // Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm,
      };
      this.GET_MemberList();
    },
    /** 提交群发短信表单 */
    submitMessageVisibleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_Member.replyAsks(this.MessageVisibleForm.ask_id, this.ZhanneiMessageVisibleForm)
            .then((response) => {
              this.loading = false;
              this.dialogZhanneiMessageVisible = false;
              this.GET_MemberList();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },

    /** 获取会员列表 */
    GET_MemberList() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Member.getMemberAsks(params)
        .then((response) => {
          this.loading = false;
          response.data = response.data.map(row => {
            row.member_name = unescape(row.member_name);
            return row;
          });
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
